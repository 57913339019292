<!-- 我的课程模块 [学生 / 老师 / 专家 / 助教] -->
<template>
    <!-- 我的课程 同步课模块 -->
    <div class="center-situation">
        <div class="course_list wrapper">
            <proTitle></proTitle>
            <!-- 条件筛选 -->
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="课程状态 :">
                    <el-select v-model="formInline.courseStatus" placeholder="全部" @change="getCourseList">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未开始" value="unstart"></el-option>
                        <el-option label="进行中" value="start"></el-option>
                        <el-option label="已结束" value="end"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label>
                    <el-input size="mini" v-model="formInline.searchName" @input="getCourseList" placeholder="输入课程名称"
                        suffix-icon="el-icon-search"></el-input>
                </el-form-item>
            </el-form>
            <!-- 列表组件 -->
            <cardList :dataList="courseList" :total="pages.total"
                :proid="String(this.$store.state.user.currentProject.id)" :currentPage="Number(pages.currentPage)"
                @returnPagesData="handlePagesData">
            </cardList>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formInline: { //查询项绑定value
                    lessonsPattern: "", //课程类型
                    courseStatus: "", //课程状态
                    searchName: "", //输入老师名称查询
                },
                courseList: [], //课程列表
                pages: { //分页
                    currentPage: 1,
                    pageSize: 6,
                    total: 0,
                },
            };
        },
        watch: {
            '$store.state.user.currentProject.id': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.pages.currentPage = 1;
                    this.getCourseList();
                }
            }
        },
        methods: {
            // 翻页
            handlePagesData(data) {
                // console.log('handleListData',data)
                this.pages.currentPage = data.currentPage
                this.pages.pageSize = data.pageSize
                this.getCourseList();
            },
            //获取项目下课程列表
            async getCourseList() {
                let projectId = sessionStorage.getItem('projectId');
                if (this.$store.state.user.currentProject.id === "0") return;
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: projectId ? projectId : this.$store.state.user.currentProject.id,
                    lessonsPattern: 0
                }
                this.formInline.courseStatus ? params.queryStatus = this.formInline.courseStatus : "";
                this.formInline.searchName ? params.queryName = this.formInline.searchName : "";
                let role = this.$store.state.user.role;
                if (role === 'expert') {
                    // params.teacherId = JSON.parse(sessionStorage.getItem('userinfo')).id
                    params.teacherId = this.$store.state.user.userInfo.id
                }
                if (role === 'assistant') {
                    // params.assistantId = JSON.parse(sessionStorage.getItem('userinfo')).id
                    params.assistantId = this.$store.state.user.userInfo.id
                }
                let resData = await this.$Api.Course.getCourseList(params)
                // console.log(resData)
                this.courseList = []
                if (!resData.data || resData.data.list.lenght <= 0) return
                this.courseList = resData.data.list;
                this.pages.total = resData.data.totalCount;
            },
        },
        mounted() {
            this.getCourseList();
        },
        activated() {},
    };
</script>

<style lang="less" scoped>
    @import "./less/course.less";
</style>